import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import URL from "../api";
import download from "downloadjs";
import sad from "../assets/sadtone.mp3";
import happy from "../assets/success.mp3";
import PageHeader from "../component/PageHeader";

const Scanner = ({ items, warehouse_uid }) => {
  const [skuKey, setSkuKey] = useState("");
  const [newItem, setNewItems] = useState(null);
  const [status, setStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [notFound, setNotFound] = useState([]);
  const [inUse, setInUse] = useState(true);
  const [binLocation, setBinLocation] = useState("");
  const [pressed, setPressed] = useState(false);
  const inputRef = useRef();
  
  useEffect(() => {
    if (inUse) {
      inputRef.current.focus();
    }
  });
  useEffect(() => {
    const oldarr = [];
    items.map((i) => {
      i.on_hand = 0;
      return oldarr.push(i);
    });
    setNewItems(oldarr);
  }, [items]);

  const scanHandler = async (e) => {
    setInUse(false);
    const audio = new Audio(sad);
    const audios = new Audio(happy);
    e.preventDefault();
    var isSku = newItem.find((el) => el.sku.toUpperCase() === skuKey.toUpperCase());
    var isSku2 = newItem.find((el) => el.barcode.toUpperCase() === skuKey.toUpperCase());
    var isSku3 = newItem.find((el) => el.sku.toUpperCase() === skuKey.toUpperCase() + "-");
    if (!isSku && isSku2) {
      isSku = true;
    } else if (!isSku && isSku3) {
      isSku = true;
    }
    var sbin_location = newItem.bin_location;
    //Handle items that aren't yet scanned
    if (!isSku) {
      setMessage(`${skuKey} Not found in the bin location, perhaps this belongs somewhere else`);
      audio.play();
      setStatus(false);
      const old = notFound;
      old.push({ sku: skuKey, bin_location: "Not Found in Bin Location" });
      console.log(old);
      setNotFound(old);
      clearMessage();
      var result = await axios.post(`${URL}/single-inventory`, {
        sku: skuKey,
        warehouseUid: warehouse_uid,
      });
      //Handle items that are already scanned
    } else {
      for (const it of newItem) {
        if (it.sku.toUpperCase() === skuKey.toUpperCase() || it.barcode.toUpperCase() === skuKey.toUpperCase() || it.sku.toUpperCase() === skuKey.toUpperCase() + "-") {
          if (it.on_hand < 1) {
            var results = await axios.post(`${URL}/single-inventory`, {
              //sku: skuKey,
              sku: it.sku.toUpperCase(),
              warehouseUid: warehouse_uid,
            });
          }
        }
      }
      setStatus(true);
      audios.play();
      setMessage(`Found and Scanned`);
      clearMessage();
    }
    if (!isSku){
      if (!result.data) {
        setSkuKey("");
      } else {
        /**alert(JSON.stringify(result.data));
        newItem.map((item, index) => {
          if (item.sku === result.data.sku) {
            //alert(result.data.sku);
            item.on_hand += 1;
            if (item.on_hand <= 1) {
              if (!results.data) {
                console.log("something");
              } else {
                item.expected = results.data.available;
              }
            }
            newItem.splice(0, 0, newItem.splice(index, 1));
            //newItem.splice(0, 0, newItem.splice(index, 1)[0]);
          }
          return newItem; 
        }); */
        setSkuKey("");
      }
    } else {
      //alert("Something");
      newItem.map((item, index) => {
        if (item.sku.toUpperCase() === skuKey.toUpperCase() || item.barcode.toUpperCase() === skuKey.toUpperCase() || item.sku.toUpperCase() === skuKey.toUpperCase() + "-") {
          item.on_hand += 1;
          if (item.on_hand <= 1) {
            if (!results.data) {
              console.log("something");
            } else {
              item.expected = results.data.available;
            }
          }
          //alert(JSON.stringify(newItem[index]);
          //newItem.unshift(newItem[index]);
          newItem.splice(0, 0, newItem.splice(index, 1)[0]);
          //newItem.splice(0, 0, newItem.splice(index, 1)[0]);
        }
        return newItem;
      });
      setSkuKey("");
    }
  };

  const clearMessage = () => {
    setTimeout(() => {
      setMessage("");
    }, 2000);
  };

  const csvHandler = () => {
    const oldItems = JSON.parse(localStorage.getItem("oldItems"));
    let differenceArray = [];
    let inventorySnapshotArray = [];

    // Prepare CSV for scanned items and differences
    newItem.map((item) => {
      const findItem = oldItems.find((el) => el.sku === item.sku);
      if (findItem) {
        // Check for differences in quantity
        if (item.on_hand !== findItem.on_hand) {
          const diffObj = {
            sku: item.sku,
            product_name: item.product_name,
            bin_location: item.bin_location,
            expected: findItem.on_hand,
            actual: item.on_hand,
          };
          differenceArray.push(diffObj);
        }
      } else {
        // Item scanned but not found in old inventory (unexpected)
        differenceArray.push({
          sku: item.sku,
          product_name: item.product_name,
          bin_location: item.bin_location,
          expected: "Not in Expected Inventory",
          actual: item.on_hand,
        });
      }
      return differenceArray;
    });

    // Now check for items that weren't scanned but were expected
    oldItems.map((oldItem) => {
      const findNewItem = newItem.find((el) => el.sku === oldItem.sku);
      if (!findNewItem) {
        // Item expected but not scanned
        differenceArray.push({
          sku: oldItem.sku,
          product_name: oldItem.product_name,
          bin_location: oldItem.bin_location,
          expected: oldItem.on_hand,
          actual: "Not Scanned",
        });
      }
      return differenceArray;
    });

    // Prepare CSV for inventory snapshot (all items in new inventory)
    newItem.map((item) => {
      const snapshotObj = {
        sku: item.sku,
        product_name: item.product_name,
        bin_location: item.bin_location,
        on_hand: item.on_hand,
      };
      inventorySnapshotArray.push(snapshotObj);
      return inventorySnapshotArray;
    });

    // Convert arrays to CSV format and download the files
    const convertToCSV = (arr) => {
      const csvRows = [];
      const headers = Object.keys(arr[0]);
      csvRows.push(headers.join(","));
      for (const row of arr) {
        const values = headers.map((header) => row[header]);
        csvRows.push(values.join(","));
      }
      return csvRows.join("\n");
    };

    // Generate and download CSV files
    const differenceCSV = convertToCSV(differenceArray);
    const inventorySnapshotCSV = convertToCSV(inventorySnapshotArray);

    // Use download.js to trigger file downloads
    download(differenceCSV, "inventory-differences.csv", "text/csv");
    download(inventorySnapshotCSV, "inventory-snapshot.csv", "text/csv");
};

  const resetHandler = () => {
    const oldarr = [];
    items.map((i) => {
      i.on_hand = 0;
      return oldarr.push(i);
    });
    setNewItems(oldarr);
  };

  const updateHandler = async () => {
    setPressed(true);
    const newarr = [];
    const arr = [];
    const arrMov = [];
    var warehouseId = await axios.post(`${URL}/warehouse-name-to-id`, {
      data: warehouse_uid
    });
    newItem.map((i) => {
      if (!binLocation || binLocation === null || binLocation === '') {
	      setBinLocation(i.bin_location.toString());
      }
      /**if (i.on_hand === 0) {
        if (!binLocation || binLocation === null || binLocation === '') {
          setBinLocation(i.bin_location.toString());
        }
        const obj2 = {
          location_id: warehouseId.data.toString(),
          inventory_item_id: i.inventory_id.toString(),
          available: 0,
          reason: "Stock Take " + i.bin_location.toString(),
          note: "Not Scanned Zeroed Out"
        };
        return arr.push(obj2);
      } else {*/
        if (!binLocation || binLocation === null || binLocation === '') {
          setBinLocation(i.bin_location.toString());
        }
        const obj = {
          inventoryItemId: `gid://shopify/InventoryItem/${i.inventory_id}`,
          availableDelta: i.on_hand - i.expected
        }

        return newarr.push(obj);
      //}
    });
    newItem.map((i) => {
      //if (i.on_hand != 0) {
        if (!binLocation || binLocation === null || binLocation === '') {
          setBinLocation(i.bin_location.toString());
        }
      const obj3 = {
        location_id: warehouseId.data.toString(),
        inventory_item_id: i.inventory_id.toString(),
        available: i.on_hand,
        reason: "Stock Take " + i.bin_location.toString(),
        note: "Stock Take Adjustment"
      }
      return arrMov.push(obj3);
    //}
    });
    //setStatus(true);
    //setMessage(`Updating On Shopify`);
    //clearMessage();
    const arrayOfArrays = [warehouse_uid, newarr, newItem[0].bin_location.toString(), arrMov];
    const response = await axios.post(`${URL}/graphql-update-shopify`, {
      data: arrayOfArrays,
    });
    if (response.status == 200) {
      setStatus(true);
      setMessage(`Updating On Shopify`);
      clearMessage();
    }
    /** if (response.status == 200) {
      const responses = await axios.put(`${URL}/update-sellbrite`, {
        data: arr,
      });
    } */
  };

  const increaseHandler = async (i) => {
    setInUse(false);
    newItem.map((item, index) => {
      if (item.sku === i.sku) {
        item.on_hand += 1;
        //newItem.splice(0, 0, newItem.splice(index, 1)[0]);
      }
      return newItem;
    });
    setStatus(true);
    setMessage(`${i.sku} Updated`);
    clearMessage();
  };

  const decreaseHandler = async (i) => {
    setInUse(false);
    newItem.map((item, index) => {
      if (item.sku === i.sku) {
        item.on_hand -= 1;
      }
      //newItem.splice(0, 0, newItem.splice(index, 1)[0]);
      return newItem;
    });
    setStatus(true);
    setMessage(`${i.sku} Updated`);
    clearMessage();
  };

  return (
    <>
      <PageHeader title="Scanner" />
      <div className="page__body">
        <div>
          <div className="mb-4 d-flex justify-content-between align-items-center">
            <span className={`alert alert-primary m-0`}>
              {items ? items.length : 0} Available Inventory Item to Scan,
              {!items && " Please Load New Inventory Item"}
            </span>
            <div>
              <button onClick={resetHandler} className="button button__yellow">
                Reset
              </button>
              <button className="button button__blue mx-1" onClick={csvHandler}>
                Generate CSV
              </button>
              <button
                disabled={pressed}
                className="button button__dark mx-1"
                onClick={updateHandler}
              >
                Update on Shopify
              </button>
            </div>
            <Link to="/inventory">
              <button className="button button__red mx-1">Go Back</button>
            </Link>
          </div>
          {message && (
            <div
              className={`btn ${
                status ? "btn-success" : "btn-danger"
              } d-block text-left`}
            >
              {message}
            </div>
          )}
          <form
            onSubmit={scanHandler}
            className="shadow__box d-flex align-items-center"
          >
            <input
              disabled={!items}
              type="text"
              className="custom__form_input mr-2 w-100"
              placeholder="Enter SKU to Search"
              onChange={(e) => setSkuKey(e.target.value)}
              value={skuKey}
              ref={inputRef}
            />
            <button disabled={!items} className="button button__dark">
              Scan
            </button>
          </form>
        </div>

        <div>
          <table className="table my-3 bg-white">
            <thead>
              <tr>
                <th scope="col">Barcode</th>
                <th scope="col">SKU</th>
                <th scope="col">Product Name</th>
                <th scope="col">Bin Location</th>
                <th scope="col">On Hand</th>
                <th scope="col">Expected</th>
              </tr>
            </thead>

            <tbody>
              {newItem?.map((i) => (
                <tr key={i.sku}>
                  <td>{i.barcode}</td>
                  <td>{i.sku}</td>
                  <td>{i.name}</td>
                  <td>{i.bin_location}</td>
                  <td>{i.on_hand}</td>
                  <td>{i.expected}</td>
                  <td>
                      <div className="d-flex">
                        <i
                          onClick={() => increaseHandler(i)}
                          class="btn btn-success mr-2 fas fa-chevron-circle-up"
                        ></i>
                        <i
                          onClick={() => decreaseHandler(i)}
                          class="btn btn-danger fas fa-chevron-circle-down"
                        ></i>
                      </div>
                    </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

const mapState = ({ items, warehouse_uid }) => {
  return {
    items,
    warehouse_uid,
  };
};

export default connect(mapState)(Scanner);