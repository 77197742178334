import React, { useEffect, useState, useRef } from "react";
import URL from "../api";
import axios from "axios";
import Loading from "../utils/Spinner";
import sad from "../assets/sadtone.mp3";
import happy from "../assets/success.mp3";
import Tables from "../component/Tables";
import PageHeader from "../component/PageHeader";

const Transfer = () => {
  const [warehouse, setWarehouse] = useState([]);
  const [fromWarehouseId, setFromWarehouseId] = useState("");
  const [toWarehouseId, settoWarehouseId] = useState("");
  const [status, setStatus] = useState(false);
  const [skuKey, setSkuKey] = useState("");
  const [qtyKey, setQtyKey] = useState("");
  const [currentItems, setCurrentItems] = useState([]);
  const [itemsToUpdate, setItemsToUpdate] = useState([]);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState("");
  const [updateState, setUpdateState] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [stateUpdate, setStateUpdate] = useState(false);
  const [inUse, setInUse] = useState(true);

  useEffect(() => {
    fetch(`${URL}/warehouse`)
      .then((data) => data.json())
      .then((res) => setWarehouse(res))
      .catch((err) => console.log(err));
  }, []);

  const inputRef = useRef();
  useEffect(() => {
    if (inUse) {
      inputRef.current.focus();
    }
  });

  const loadOption = warehouse.map((i) => {
    return (
      <option key={i.id} value={i.id}>
        {i.name}
      </option>
    );
  });

  const updateListHandler = async (e) => {
    e.preventDefault();
    setCurrentItems([]);
    if(qtyKey == "") {
      return setMessage(
        "Enter Qty"
      );
    }
    if (fromWarehouseId === toWarehouseId) {
      return setMessage(
        "You can't Select same From Warehouse and To Warehouse"
      );
    }

  }

  const scanSkuHandler = async (e) => {
    setInUse(true);
    e.preventDefault();
    //setCurrentItems([]);
    const audio = new Audio(sad);
    const audios = new Audio(happy);
    audio.pause();
    audios.pause();
    if (fromWarehouseId === toWarehouseId) {
      return setMessage(
        "You can't Select same From Warehouse and To Warehouse"
      );
    }
    if (skuKey) {
      setStatus(true);
      setMessage("");
      setSuccessMessage("");
      const results = await axios.post(`${URL}/single-inventory`, {
        sku: skuKey,
        warehouseUid: toWarehouseId.toString(),
      });
      var founded = '';
      const prevItems = [];
      const updateItems = itemsToUpdate;
      const displayItems = currentItems;
      founded = displayItems.find((el) => el?.sku === results.data.sku);
      const fromResults = await formHandler();
      const toResults = await formHandler2();
      if(founded) {
        // create a variable that will update the display state
        updateItems.map((el, index) => {
          if (el?.sku === founded.sku) {
            if (el?.warehouse_uuid === fromWarehouseId) {
              el.scanned += 1;
              el.on_hand -= 1;
            } else {
              el.scanned += 1;
              el.on_hand += 1;
            }
            updateItems.splice(0, 0, updateItems.splice(index, 1)[0]);
          }
          return el;
        });
        //create a variable that will update the update state
        displayItems.map((el, index) => {
          if (el?.sku === founded.sku) {
            el.on_hand += 1;
            el.on_hand_other -= 1;
            el.scanned += 1;
            displayItems.splice(0, 0, displayItems.splice(index, 1)[0]);
          }
        });
        setCurrentItems(displayItems);
        setItemsToUpdate(updateItems);
        audios.play();
        setSuccessMessage("Item Quantity Increased");
      } else {
        if (fromResults !== null) {
          var toInventory = Number(toResults.available) < 0 ? 1 : Number(toResults.available + 1);
          var display = {
            sku: fromResults?.sku,
            warehouse_uuid: toWarehouseId,
            on_hand: toInventory,
            on_hand_other: Number(fromResults.available - 1),
            scanned: 1,
            //bin_location: toResults.bin_location,
          };
          var toResult = {
            sku: fromResults?.sku,
            warehouse_uuid: toWarehouseId,
            on_hand: toInventory,
            scanned: 1,
            inventory_item_id: fromResults.inventory_item_id
            //bin_location: toResults.bin_location,
          };
          var fromResult = {
            sku: fromResults?.sku,
            warehouse_uuid: fromWarehouseId,
            on_hand: Number(fromResults.available - 1),
            scanned: 1,
            inventory_item_id: fromResults.inventory_item_id
            //bin_location: fromResults.bin_location
          };
          fromResult.on_hand -= Number(qtyKey);
          displayItems.push(display);
          prevItems.push(fromResult);
          prevItems.push(toResult);
          updateItems.push(fromResult);
          updateItems.push(toResult);
          console.log(prevItems);
          setCurrentItems(displayItems);
          setItemsToUpdate(updateItems);
          setStatus(false);
          // setSkuKey("");
          audios.play();
          setSuccessMessage("Item scanned successfully");
        }
      }
    } else {
      setMessage("Please Enter SKU");
    }
    setQtyKey("");
    setSkuKey("");
    setUpdateState(!updateState);
    setStatus(false);
  };

  const updateHandler = async () => {
    setStatus(true);
    setSuccessMessage("");
    const newarr = [];
    const arr = [];
    itemsToUpdate.map((i) => {
      if (i.warehouse_uuid === fromWarehouseId) {
        const fromInventory = i.on_hand < 0 ? 0 : i.on_hand.toString();
        const obj = {
          location_id: fromWarehouseId.toString(),
          inventory_item_id: i.inventory_item_id.toString(),
          available: fromInventory,
          reason: "Transfer Between Warehouses",
          note: "Decrease", 
	        warehouse: toWarehouseId.toString(),
	        adjustment: i.scanned * -1
        };
        return newarr.push(obj);
      }
    });
    itemsToUpdate.map((i) => {
      if (i.warehouse_uuid === toWarehouseId) {
        const obj = {
            location_id: toWarehouseId.toString(),
            inventory_item_id: i.inventory_item_id.toString(),
            available: i.on_hand.toString(),
            reason: "Transfer Between Warehouses",
            note: "Increase",
	          warehouse: fromWarehouseId.toString(),
	          adjustment: i.scanned
        };
        return arr.push(obj);
      }
    });
    const arrayOfArrays = [fromWarehouseId, toWarehouseId, newarr, arr];
    //const response = await axios.put(`${URL}/update-sellbrite`, {
    const response = await axios.put(`${URL}/new-transfers`, {
      data: newarr,
    });
    if (response.status == 200) {
    //const responses = await axios.put(`${URL}/update-sellbrite`, {
      var responses = await axios.put(`${URL}/new-transfers`, {
        data: arr,
      });
    }
    if (responses.status == 200) {
      setSuccessMessage("Items Updated On Shopify");
      setCurrentItems([]);
      setItemsToUpdate([]);
      setStatus(false);
    }
    //setSuccessMessage("Items Updated On Shopify");
    //setCurrentItems([]);
  };

  const formHandler = async () => {
    const result = await axios.post(`${URL}/single-inventory`, {
      sku: skuKey,
      warehouseUid: fromWarehouseId,
    });
    const resultItem = result.data;
    if (result.data.length <= 0) {
      setMessage("No Sku Found in From Warehouse");
      return null;
    } else {
      return resultItem;
    }
  };

  const increaseHandler = (index) => {
    setInUse(false);
    const prevItems = [];
    const updateItems = itemsToUpdate;
    const displayItems = currentItems;
    const item = prevItems[index];
    var items = index;
    updateItems.map((el, index) => {
      if (el?.sku === items.sku) {
        if (el?.warehouse_uuid === fromWarehouseId) {
          el.on_hand -= 1;
          el.scanned += 1;
        } else {
          el.scanned += 1;
          el.on_hand += 1;
        }
      }
        updateItems.splice(0, 0, updateItems.splice(index, 1)[0]);
      return el;
    });
    //create a variable that will update the update state
    displayItems.map((el, index) => {
      if (el?.sku === items.sku) {
        el.on_hand += 1;
        el.on_hand_other -= 1;
        el.scanned += 1;
      }
        //displayItems.splice(0, 0, displayItems.splice(index, 1)[0]);
    });
    setCurrentItems(displayItems);
    setItemsToUpdate(updateItems);
    //audios.play();
    setSuccessMessage("Item Quantity Increased");
    setStateUpdate(!stateUpdate);
  };

  const decreaseHandler = (index) => {
    setInUse(false);
    const prevItems = [];
    const updateItems = itemsToUpdate;
    const displayItems = currentItems;
    const item = prevItems[index];
    var items = index;
    updateItems.map((el, index) => {
      if (el?.sku === items.sku) {
        if (el?.warehouse_uuid === fromWarehouseId) {
          el.scanned += 1;
          el.on_hand += 1;
        } else {
          el.scanned += 1;
          el.on_hand -= 1;
        }
      }
        updateItems.splice(0, 0, updateItems.splice(index, 1)[0]);
      return el;
    });
    //create a variable that will update the update state
    displayItems.map((el, index) => {
      if (el?.sku === items.sku) {
        el.on_hand -= 1;
        el.on_hand_other += 1;
        el.scanned -= 1;
      }
        //displayItems.splice(0, 0, displayItems.splice(index, 1)[0]);
    });
    setCurrentItems(displayItems);
    setItemsToUpdate(updateItems);
    //audios.play();
    setSuccessMessage("Item Quantity Decreased");
    setStateUpdate(!stateUpdate);
  };

  const formHandler2 = async () => {
    const result = await axios.post(`${URL}/single-inventory`, {
      sku: skuKey,
      warehouseUid: toWarehouseId,
    });
    const resultItem = result.data;
    if (result.data.length <= 0) {
      setMessage("No Sku Found in From Warehouse");
      return null;
    } else {
      return resultItem;
    }
  };

  return (
    <>
      <PageHeader title="Transfer Between Warehouses" />
      <div className="page__body">
        {message && <div className="alert alert-danger">{message}</div>}
        {successMessage && (
          <div className="alert alert-success">{successMessage}</div>
        )}
        <div className="shadow__box d-flex align-items-end">
          <div>
            <label className="d-block" htmlFor="">
              From Warehous
            </label>
            <select
              defaultValue="Select Warehouse"
              className="custom__form_input mr-3"
              onChange={(e) => setFromWarehouseId(e.target.value)}
            >
              <option disabled selected>
                From Warehouse
              </option>
              {loadOption}
            </select>
          </div>
          <div className="">
            <label className="d-block" htmlFor="">
              To Warehouse
            </label>
            <select
              defaultValue="Select Warehouse"
              className="custom__form_input mr-3"
              onChange={(e) => settoWarehouseId(e.target.value)}
            >
              <option disabled selected>
                To Warehouse
              </option>
              {loadOption}
            </select>
          </div>

          <form className="d-flex align-items-center" onSubmit={scanSkuHandler}>
            <input 
              autoFocus
              type="text"
              className="custom__form_input mr-3"
              placeholder="Enter Sku"
              value={skuKey}
              onChange={(e) => setSkuKey(e.target.value)}
              disabled={status}
              ref={inputRef}
            />
          </form>
          <button 
            onClick={updateHandler}
            disabled={status} 
            className="button button__dark"
          >
              Update On Shopify
            </button>
        </div>
        <div className="text-center">{status && <Loading />}</div>
        <table className="table my-3 bg-white">
      <thead>
        <tr>
          <th scope="col" className="warehouse-number">warehouse_uuid</th>
          <th scope="col">SKU</th>
          <th scope="col">Scanned</th>
          <th scope="col">On Hand From Warehouse</th>
          <th scope="col">On Hand To Warehouse</th>
          <th scope="col">Manual Adjustment</th>
        </tr>
      </thead>

      <tbody>
        {currentItems?.map((k) => (
          <tr key={k?.sku + k?.warehouse_uuid}>
            <td className="warehouse-number">{k?.warehouse_uuid}</td>
            <td>{k?.sku}</td>
            <td>{k?.scanned}</td>
            <td>{k?.on_hand_other}</td>
            <td>{k?.on_hand}</td>
            <td>
                      <div className="d-flex">
                        <i
                          onClick={() => increaseHandler(k)}
                          class="btn btn-success mr-2 fas fa-chevron-circle-up"
                        ></i>
                        <i
                          onClick={() => decreaseHandler(k)}
                          class="btn btn-danger fas fa-chevron-circle-down"
                        ></i>
                      </div>
                    </td>
          </tr>
        ))}
      </tbody>
    </table>
      </div>
    </>
  );
};

export default Transfer;
